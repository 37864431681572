.primaryBut {
  width: fit-content;
  border-radius: 16px;
  background: rgba(97, 50, 242, 0.80);
  padding: 12px 16px;
  border: none;
  color: #ffffff;
  text-decoration: none;
}

.primaryBut:hover {
  background: rgba(97, 50, 242, 0.50);
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.10);
  color: #ffffff;
}

.buttons {
  padding: 16px 0px;
}

.header {
  width: 90vw;
  height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  filter: var(--hover);
  gap: space-between;
  position: fixed;
  top: 10px;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;
  z-index: 5;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.header-hide {
  width: 90vw;
  height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  filter: var(--hover);
  gap: space-between;
  position: fixed;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;
  z-index: 5;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  top: -80px;
}

#nav {
  display: flex;
  width: 100vw;
  flex-direction: row;
  gap: 6%;
  justify-content: center;
  align-items: center;
}

.navLink {
  color: var(--on-surface);
  text-decoration: none;
}

.navLink:hover {
  color: var(--on-background);
  cursor: pointer;
}

#nav a.active {
  font-weight: 600;
  color: var(--primary);
}

footer {
  display: flex;
  width: 100%;
  justify-content: center;
}

.footer {
  display: flex;
  width: 100%;
  max-width: 1000px;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  justify-content: center;
  align-items: center;
  gap: 40px 160px;
  color: var(--on-surface);
}

.footerNav {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 120px;
}

.footerNavCol {
  display: flex;
  flex-direction: column;
  justify-content: left;
  gap: 16px;
}

.footerNavCol ul {
  display: flex;
  flex-direction: column;
  gap: 16px;
  list-style-type: none;
}

.footerNavCol ul li a {
  color: var(--on-surface);
  text-decoration: none;
}

.footerNavCol ul li a:hover {
  color: var(--on-background);
  text-decoration: none;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 80px;
  margin-top: calc(5% + 100px);
  margin-bottom: calc(5% + 80px);
}

.headline {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  max-width: 60%;
  min-width: 300px;
  gap: 16px;
  margin-bottom: 5%;
}

.headline p {
  max-width: 95%;
}

#center {
  align-items: center;
  margin-bottom: 0;
  text-align: center;
  /* max-width: 80%; */
}

.projectCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
}

.projectLink {
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  padding: 0;
  min-width: 360px;
  color: var(--on-surface);
}

.projectCardContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
}

.projectLink a {
  text-decoration: none;
  color: unset;
}

.projectLink a:hover {
  color: unset;
}

.projectLink:hover {
  cursor: pointer;
  color: var(--on-background);
  filter: var(--hover);
  transform: scale(1.01);
}

.projectTitle {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  width: fit-content;
  max-width: 52%;
  min-width: 300px;
  gap: 16px;
}

.projectTitle .title {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.projectTitle .overline {
  color: var(--on-primary-container);
}

.projectTitle .divider {
  height: 1px;
  width: 100%;
  background-color: var(--on-primary-container);
}

.projectTitle .primaryBut {
  margin: 0;
}

.projectCover img {
  display: flex;
  width: 100%;
}

.projects {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: 64px;
}

#project1 {
  flex: 1 0 calc(100%/3*1.2 - 20px);
}

#project2 {
  flex: 1 0 calc(100%/3*1.8 - 20px);
}

#project3 {
  width: 100%;
}

.inProcess {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: 20px;
  width: 100%;
}

.inProcess .projectLink {
  width: 100%;
}

#funProjects .projectLink {
  padding: 48px 24px 0px 24px;
}

#funProjects .projectTitle {
  align-items: flex-start;
  text-align: left;
}

#funProjects .projectTitle h4 {
  color: var(--on-primary-container);
}

#fun1,
#fun2 {
  flex: 1 0 calc(100%/2 - 20px);
}

#fun1 img,
#fun2 img {
  border-radius: 25px 25px 0px 0px;
}

.bio {
  display: flex;
  width: 90%;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 40px 80px;
}

.bioPic {
  display: flex;
  flex: 1 0 calc(100%/3 - 80px);
  min-width: 255px;
  max-width: 355px;
}

.bioPic img {
  display: flex;
  width: 100%;
}

.bioText {
  display: flex;
  flex: 1 0 calc(100%/3*2 - 80px);
  flex-direction: column;
  justify-content: center;
  align-items: left;
  gap: 16px;
}

.itemTitle {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  gap: 8px;
  color: var(--on-primary-container);
}

.gridItem {
  display: flex;
  flex-direction: column;
  flex: 1 0 calc(100%/2 - 100px);
  min-width: 250px;
}

.grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  gap: 24px;
  border-radius: 48px;
  padding: 48px;
}

.innerContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
}

#designGrid {
  gap: 24px 100px;
  background: var(--primary-container);
}

#interestGrid {
  width: 80%;
  padding: 0;
}

#interestGrid .interestPic {
  display: flex;
  flex: 0 1 calc(100%/2 - 24px);
  max-width: 350px;
  min-width: 180px;
}

.interestPic img {
  display: flex;
  width: 100%;
}



@keyframes downwards {
  0% {
    top: 0px;
  }

  100% {
    top: 70px;
  }
}