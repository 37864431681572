.prevNext {
    display: flex;
    width: 90vw;
    justify-content: space-between;
}

.prevNext a {
    display: flex;
    align-items: center;
    text-decoration: none;
}


.projectHeadline {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
    gap: 16px;
    color: var(--on-surface);
}

.projectHmw {
    width: 35%;
    min-width: 300px;
}

.projectOverview {
    display: flex;
    border-radius: 24px;
}

#cr_overview {
    background-color: var(--blue);
}

#io_overview {
    background-color: var(--green);
}

#io_cover {
    background-color: var(--green);
}

#whoami_cover {
    background-color: var(--grey);
}

.projectOverview img {
    display: flex;
    width: 100%;
}

.projectHeadline .overline {
    color: var(--primary);
    font-weight: 500;
}

.projectIntro {
    padding-top: 16px;
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    gap: 5%;
}

.innerProjectIntro {
    display: flex;
    flex-flow: column wrap;
    text-align: left;
    align-items: flex-start;
}

.innerProjectIntro ul {
    list-style: none;
    text-align: left;
}

.rows {
    display: flex;
    flex-direction: column;
    gap: 80px;
}

.projectContentRow {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    max-width: 900px;
    justify-content: left;
    gap: 16px;
}

.projectContentRow h4 {
    width: 189px;
    flex-shrink: 0;
}

.projectContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 900px;
    gap: 16px;
}

.projectContentFull {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    gap: 16px;
}

.projectDesign {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90vw;
    padding: 40px;
    gap: 40px;
    border-radius: 24px;
}

.projectContent h4 {
    align-self: stretch;
}

ul {
    /* padding: 0px; */
    list-style: inside;
}

.featureContent ul li {
    padding: 4px 0;
}

.projectContent .jtbd {
    display: flex;
    padding: 48px 8%;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 24px;
    align-self: stretch;
    background-color: var(--primary-container);
    border-radius: 24px;
}

.projectImg {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    gap: 8px;
    border-radius: 24px;
}

.projectImg img {
    display: flex;
    width: 100%;
}

.projectImg video {
    display: flex;
    width: 100%;
    border-radius: 24px;
}

.projectImg .imgCaption {
    display: flex;
    align-items: center;
}

.imgGallery {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 8px;
    width: 100%;
}

#cr-persona {
    flex: 1;
}

#cr-journey {
    flex: 2;
    align-self: flex-end;
}

#cr-improvedMap {
    flex: 1;
}

#cr-strategies {
    flex: 1;
    align-self: flex-end;
}

.highlightSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90vw;
    padding: 40px;
    gap: 80px;
    background-color: rgba(230, 222, 255, 0.5);
    border-radius: 24px;
}

.feature {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    row-gap: 16px;
    column-gap: 8px;
    padding: 16px 0px;
}

#reverse {
    flex-direction: row-reverse;
}

.feature .projectImg {
    flex: 1 1 calc(100%/2 - 8px);
    min-width: 330px;
    max-width: 600px;
}

.feature .projectImg video {
    display: flex;
    width: 70%;
    max-width: 300px;
    border-radius: 24px;
}

.feature .featureContent {
    display: flex;
    flex-direction: column;
    flex: 1 0 calc(100%/2 - 8px);
    gap: 16px;
    min-width: 270px;
}

.bulletPoints {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-top: 16px;
}

@media screen and (max-width: 560px) {
    .projectContentRow {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        max-width: 900px;
        gap: 16px;
    }


    .imgGallery {
        flex-direction: column;
        align-items: center;
        gap: 16px;
    }
}