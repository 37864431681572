* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  padding: 5%;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  scroll-behavior: smooth;
  background-color: var(--background);
  color: var(--on-background);
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  z-index: -1;
  background-image: var(--gradient-background);
  background-size: 400%;
  animation: bg-animation 5s infinite alternate;
}

.background::after{
  content: "";
  width: 100%;
  height: 30%;
  position:absolute;
  top: 70%;
  left: 0;
  background: var(--opaque-background);
}

@keyframes bg-animation {
  0% {
    background-position: left;
  }

  100% {
    background-position: right;
  }
}

:root {
  --background: #FDF8FD;
  --on-background: #1C1B1E;
  --surface: #E6E0EC;
  --on-surface: #48454E;
  --surface-variant: #FFFFFF;
  --gradient: linear-gradient(45deg, #6132F2, #605C71);
  --primary: #6132F2;
  --primary-active: #1C0062;
  --secondary: #605C71;
  --on-primary-container: #1C0062;
  --primary-container: #E6DEFF;
  /* variant colors */
  --blue: #DEE2FF;
  --pink: #F7DFE9;
  --grey: #E9DDF2;
  --orange: #EFD3D7;
  --green: #E0E0DF;
  --hover: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.20));
  /* gradient background */
  --gradient-background: linear-gradient(
   to right,
   var(--background),
   var(--blue),
   var(--pink),
   var(--background));
  --opaque-background: linear-gradient(
    to bottom,
    rgba(253, 248, 253, 0),
    rgba(253, 248, 253, 1)
  )
}

.blue {
  background-color: var(--blue);
}

.pink {
  background-color: var(--pink);
}

.grey {
  background-color: var(--grey);
}

.orange {
  background-color: var(--orange);
}

.green {
  background-color: var(--green);
}

.white {
  background-color: var(--surface-variant);
}

.primary-container {
  background-color: var(--primary-container);
}

.gradientText {
  background-color: var(--primary);
  background-image: var(--gradient);
  background-size: 100%;
  background-repeat: repeat;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

h1, h2, h3, h4, h5 {
  font-family: 'Yeseva One';
  font-weight: 400;
  font-size: clamp(var(--min), var(--val), var(--max));
}

h1 {
  --min: 2.5rem;
  --val: 6vw + 1rem;
  --max: 6rem;
  letter-spacing: -1.5px;
}

h2 {
  --min: 1.875rem;
  --val: 4vw + 1rem;
  --max: 4rem;
  letter-spacing: -1px;
}

h3 {
  --min: 1.25rem;
  --val: 2.2vw + 1rem;
  --max: 3rem;
  letter-spacing: -0.5px;
}

h4 {
  --min: 1.25rem;
  --val: 2vw + 1rem;
  --max: 2.125rem;
  letter-spacing: -0.25px;
  /* try this? clamp(1.25rem, 7vw - 1rem, 2.125rem); */
}

h5 {
  --min: 1.125rem;
  --val: 1.6vw + 1rem;
  --max: 1.5rem;
}

.subtitle, p, .small, .overline, a, .caption {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: clamp(var(--min), var(--val), var(--max));
}

.caption {
  font-style: italic;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 12px;
  color: var(--on-surface);
}

.subtitle {
  font-weight: 500;
  letter-spacing: 0.15px;
  --min: 0.875rem;
  --val: 5vw - 1rem;
  --max: 1.125rem;
}

p {
  letter-spacing: 0.15px;
  line-height: 150%;
  --min: 0.875rem;
  --val: 5vw - 1rem;
  --max: 1.125rem;
}

.small {
  letter-spacing: 0.25px;
  --min: 0.75rem;
  --val: 5vw - 1rem;
  --max: 0.875rem;
}

.overline {
  letter-spacing: 1px;
  text-transform: uppercase;
  --min: 0.625rem;
  --val: 5vw - 1rem;
  --max: 0.875rem;
}

a {
  font-weight: 500;
  color: var(--primary);
  text-decoration: underline;
  --min: 0.875rem;
  --val: 5vw - 1rem;
  --max: 1.125rem;
}

a:hover {
  color: var(--on-primary-container);
}

.secondary {
  color: var(--secondary);
}

.primary {
  color: var(--primary);
}